import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/ApplicationsForDigitalPainting/artrage.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/ApplicationsForDigitalPainting/artweaver.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/ApplicationsForDigitalPainting/deeppaint.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/ApplicationsForDigitalPainting/dogwaffle.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/ApplicationsForDigitalPainting/gimp.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/ApplicationsForDigitalPainting/header.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/ApplicationsForDigitalPainting/opencanvas.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/ApplicationsForDigitalPainting/paint.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/ApplicationsForDigitalPainting/painter.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/ApplicationsForDigitalPainting/paintshoppro.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/ApplicationsForDigitalPainting/photoshop.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/ApplicationsForDigitalPainting/sketchbookpro.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/alexanderdraude.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/android.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/anry.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/aronstgoddard.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/artpad.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/barontieri.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/craigmullins.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/dandossantos.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/danielieske.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/dominiceise.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/dusso.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/fengzhu.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/francishsu.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/georgehull.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/header.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/hpx1.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/iankim.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/jasonchan.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/jonfoster.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/justinsweet.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/markodjurdjevic.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/mercilessdesign.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/mon.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/nickklein.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/nivbed.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/prometheus.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/ryanchurch.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/scottrobertson.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/sonsofthestorm.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/sparth.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/stevenstahlberg.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/sub.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/torstenwolber.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/vancekovacs.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/vyle.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/DigitalPainters/xiaoyechen.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/GraphicsTablet/header.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/HowToSetUpColorManagement/header.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/HowToSetUpColorManagement/saveforwebsettings.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/HowToSetUpColorManagement/settings.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/LikenessInPortrait/header.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/LikenessInPortrait/whoisthis.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/LikenessInPortrait/whoisthis2.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/LikenessInPortrait/whoisthis3.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/PhotoshopBrushEngine/airbrush.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/PhotoshopBrushEngine/angle.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/PhotoshopBrushEngine/brightnessjitter.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/PhotoshopBrushEngine/brush-tip-shape.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/PhotoshopBrushEngine/brushbutton.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/PhotoshopBrushEngine/dual-brush.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/PhotoshopBrushEngine/flow.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/PhotoshopBrushEngine/foreground-backgroundjitter.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/PhotoshopBrushEngine/header.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/PhotoshopBrushEngine/huejitter.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/PhotoshopBrushEngine/jitter.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/PhotoshopBrushEngine/noise.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/PhotoshopBrushEngine/opacity.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/PhotoshopBrushEngine/roundness.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/PhotoshopBrushEngine/saturationjitter.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/PhotoshopBrushEngine/scattering.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/PhotoshopBrushEngine/size.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/PhotoshopBrushEngine/smoothing.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/PhotoshopBrushEngine/texture.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/PhotoshopBrushEngine/wetedges.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/TheDesignsOfStarWars/atat.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/TheDesignsOfStarWars/blockaderunner.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/TheDesignsOfStarWars/bobafett.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/TheDesignsOfStarWars/chewie.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/TheDesignsOfStarWars/cloudcity.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/TheDesignsOfStarWars/deathstar.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/TheDesignsOfStarWars/header.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/TheDesignsOfStarWars/imperialforces.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/TheDesignsOfStarWars/jabbathehut.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/TheDesignsOfStarWars/lightsaber.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/TheDesignsOfStarWars/mauser.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/TheDesignsOfStarWars/milleniumfalcon.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/TheDesignsOfStarWars/moncalamari.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/TheDesignsOfStarWars/nebulon.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/TheDesignsOfStarWars/r2d2.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/TheDesignsOfStarWars/sandcrawler.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/TheDesignsOfStarWars/shapes.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/TheDesignsOfStarWars/slave1.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/TheDesignsOfStarWars/sssbridge.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/TheDesignsOfStarWars/stardestroyer.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/TheDesignsOfStarWars/tiefighter.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/TheDesignsOfStarWars/ties.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/TheDesignsOfStarWars/tuskenraider.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/TheDesignsOfStarWars/vader.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/WacomIntuos4Review/header.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/WacomIntuos4Review/mainphoto.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/WacomIntuos4Review/pentips.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/WacomIntuos4Review/radialmenu.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/WacomIntuos4Review/touchingring.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/WhatDesignersCanLearnFromEvolution/basic.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/WhatDesignersCanLearnFromEvolution/convergence.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/WhatDesignersCanLearnFromEvolution/disadvantage.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/WhatDesignersCanLearnFromEvolution/economics.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/WhatDesignersCanLearnFromEvolution/flexibility.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/WhatDesignersCanLearnFromEvolution/header.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/WhatDesignersCanLearnFromEvolution/perfection.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/WhatDesignersCanLearnFromEvolution/quality.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listBlog/WhatDesignersCanLearnFromEvolution/usability.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/Aztecs/aztecs.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/Aztecs/preview.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/Blockator/blockator.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/Blockator/preview.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/BlondheadRedhead/blondhead.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/BlondheadRedhead/preview.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/BlondheadRedhead/redhead.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/CreatureCombat/preview.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/DigitalLifeDrawing/akt10.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/DigitalLifeDrawing/akt11.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/DigitalLifeDrawing/akt12.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/DigitalLifeDrawing/akt13.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/DigitalLifeDrawing/akt14.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/DigitalLifeDrawing/akt2.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/DigitalLifeDrawing/akt4.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/DigitalLifeDrawing/preview.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/EssenmitsosseRedesign/preview.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/EssenmitsosseRedesign/website.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/Faust/cover.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/Faust/preview.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/JackOfAllTrades/preview.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/Kino120a/allegro-non-troppo-1.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/Kino120a/allegro-non-troppo-2.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/Kino120a/allegro-non-troppo-3.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/Kino120a/allegro-non-troppo-4.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/Kino120a/anderwelten.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/Kino120a/berlincalling.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/Kino120a/im-not-there-1.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/Kino120a/im-not-there-2.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/Kino120a/im-not-there-3.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/Kino120a/im-not-there-ben-whishaw.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/Kino120a/im-not-there-cate-blanchett.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/Kino120a/im-not-there-christian-bale.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/Kino120a/im-not-there-heath-ledger.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/Kino120a/im-not-there-marcus-carl-franklin.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/Kino120a/im-not-there-richard-gere.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/Kino120a/metropolis.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/Kino120a/preview.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/Kino120a/the-day-the-earth-stood-still.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/Kino120a/underdogs.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/LinenTunic/linentunic.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/LinenTunic/preview.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/OlderPaintings/airface.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/OlderPaintings/baldface.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/OlderPaintings/dragonavenue.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/OlderPaintings/gondel.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/OlderPaintings/preview.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/OlderPaintings/saltlake.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/OlderPaintings/turtle.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/OlderPaintings/turtleline.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/PortraitClass/portrait11.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/PortraitClass/portrait12.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/PortraitClass/portrait13.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/PortraitClass/portrait3.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/PortraitClass/portrait8.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/PortraitClass/portrait9.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/PortraitClass/preview.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/RealisticallyPaintedBowser/bowser.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/RealisticallyPaintedBowser/preview.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/SonicTheRealHedgehog/preview.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/SonicTheRealHedgehog/sonic.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/SonicTheRealHedgehog/sonicpixel.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/SpartaFanart/preview.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/SpartaFanart/sparta.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/TheAnglerfish/anglerfish.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/TheAnglerfish/preview.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[slug]/content/listPortfolio/TheHumanBrain/preview.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/frontpage.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/components/navigation.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/components/logo.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/components/date.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js")